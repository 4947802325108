<template>
  <div>
    <core-page-title />

    <v-container>
      <v-row>
        <v-col>
          <btn-back
            class="mr-2"
            to="/users"
          />

          <v-btn
            color="amber accent-4"
            class="white--text ml-0"
            @click="dialogChangePassword = true"
          >
            <v-icon
              class="mr-2"
            >
              mdi-lock-reset
            </v-icon>
            Alterar senha
          </v-btn>
        </v-col>
      </v-row>

      <users-fields
        :loading-save="loadingSave"
        :loading-get="loadingGet"
        :form-validation="formValidation"
        :user="user"
        @save="save"
      />

      <v-dialog
        :value="dialogChangePassword"
        max-width="500px"
        @click:outside="dialogChangePassword = false"
      >
        <v-card>
          <v-toolbar
            color="amber"
            flat
            dense
          >
            <v-toolbar-title class="white--text font-weight-black">
              Alterar senha
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-4 text-center">
            <v-row>
              <v-col>
                <validation-observer ref="formChangePassword">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="password"
                  >
                    <v-text-field
                      v-model="newPassword"
                      :disabled="loadingChangePassword"
                      label="Nova senha"
                      type="password"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </validation-observer>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <btn-cancel
              text
              @click="dialogChangePassword = false"
            />
            <v-btn
              color="amber accent-4"
              :loading="loadingChangePassword"
              text
              @click="changePassword"
            >
              <v-icon class="mr-2">
                mdi-lock-reset
              </v-icon>
              Alterar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
  import usersApi from '@/api/users'

  export default {
    name: 'UsersEdit',

    components: {
      UsersFields: () => import('./UsersFields'),
    },

    data () {
      return {
        loadingSave: false,
        loadingGet: false,
        loadingChangePassword: false,
        dialogChangePassword: false,
        formValidation: {},
        user: {},
        newPassword: null,
      }
    },

    mounted () {
      this.get()
    },

    methods: {
      async get () {
        try {
          this.loadingGet = true

          const response = await usersApi.get(this.$route.params.id)

          this.user = response.data.user
        } catch (e) {
          this.$router.push('/users', () => this.$snackbar.show({ color: 'error', message: this.$apiError._(e) }))
        } finally {
          this.loadingGet = false
        }
      },

      async save (user) {
        try {
          this.loadingSave = true

          await usersApi.update(user.id, user)

          this.$router.push('/users', () => this.$snackbar.show({ color: 'success', message: this.$messages._('update_success') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.formValidation = this.$apiError.fieldsValidation(e)
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

      async changePassword () {
        try {
          this.loadingChangePassword = true
          this.$refs.formChangePassword.setErrors({})

          await usersApi.changePassword(this.$route.params.id, {
            password: this.newPassword,
          })

          this.dialogChangePassword = false
          this.$snackbar.show({ color: 'success', message: this.$messages._('success_change_password') })
          this.newPassword = null
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.formChangePassword.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingChangePassword = false
        }
      },

    },

  }
</script>
